import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
    faTiktok
    } from "@fortawesome/free-brands-svg-icons";

const ProfileCard = ({profile}) => {
    return (
        <div className="text-center">
            <div className="w-[120px] h-[120px] overflow-hidden mx-auto mb-5 rounded-full shadow-md">
                <img src={profile.image} alt={profile.full_name} className="w-full h-auto" />
            </div>
            <p className="text-xl font-bold">{profile.full_name}</p>
            <p>{profile.bio_creator}</p>
            <div className="flex justify-center items-center flex-row">
                {profile.facebook && 
                    <a href={`https://facebook.com/${profile.facebook}`} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon 
                        icon={faFacebook}
                        className="cursor-pointer text-slate-700 hover:text-slate-500 text-3xl mx-1"
                    />
                    </a>
                }
                {profile.instagram && 
                    <a href={`https://instagram.com/${profile.instagram}`} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon 
                        icon={faInstagram}
                        className="cursor-pointer text-slate-700 hover:text-slate-500 text-3xl mx-1"
                    />
                    </a>
                }
                {profile.twitter && 
                    <a href={`https://twitter.com/${profile.twitter}`} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon 
                        icon={faTwitter}
                        className="cursor-pointer text-slate-700 hover:text-slate-500 text-3xl mx-1"
                    />
                    </a>
                }
                {profile.tiktok && 
                    <a href={`https://tiktok.com/@${profile.tiktok}`} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon 
                        icon={faTiktok}
                        className="cursor-pointer text-slate-700 hover:text-slate-500 text-3xl mx-1"
                    />
                    </a>
                }
                {profile.youtube && 
                    <a href={`https://youtube.com/${profile.youtube}`} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon 
                        icon={faYoutube}
                        className="cursor-pointer text-slate-700 hover:text-slate-500 text-3xl mx-1"
                    />
                    </a>
                }
            </div>
            <hr className="my-5 border-white"/>
        </div>
    )
}

export default ProfileCard;