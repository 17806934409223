import {useState} from 'react';

const ImageModal = ({ src, alt }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
        <div className="mb-5">
            <div
            className="w-full h-[300px] overflow-hidden mr-4 rounded-md cursor-pointer shadow"
            onClick={openModal}
            >
            <img src={src} alt={alt} className="w-full h-auto" />
            </div>
        </div>

        {isOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black opacity-75" onClick={closeModal}></div>
            <div className="relative">
                <div className="bg-white p-3 max-w-full max-h-full overflow-auto rounded-md">
                <img src={src} alt={alt} className="max-h-[500px] rounded-md" />
                </div>
                <button
                className="absolute top-1 right-1 m-3 text-red-300 text-2xl cursor-pointer"
                onClick={closeModal}
                >
                &#x2716;
                </button>
            </div>
            </div>
        )}
        </>
    );
};

export default ImageModal;