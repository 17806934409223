import {useEffect} from "react";
import {useDispatch} from "react-redux"
import {Routes, Route} from "react-router-dom";
import {API, setAuthToken} from "../src/config/api";
import {auth, authError} from "../src/store/actions/user";
import Cookies from 'js-cookie';
// import Swal from "sweetalert2";
import "./App.css";

import Creator from "./components/Guard/Creator"
import Header from "./components/Headers/Header";
import Footer from "./components/Footer/Footer";
// // Public View
import Home from "./pages/Home";
import OrderPages from "./pages/OrderPages";
import InvoicePage from "./pages/InvoicePage";
import ProductsAuthor from "./pages/ProductsAuthor";
// Profile
import MyProfile from "./pages/Guard/Profile/MyProfile";
// Product
import MyProduct from "./pages/Guard/Product/MyProduct";
import AddProduct from "./pages/Guard/Product/AddProduct";
// Coupon
import MyCoupons from "./pages/Guard/Coupon/MyCoupons";
import AddCoupon from "./pages/Guard/Coupon/AddCoupon";
// Transaction
import MyTransactions from "./pages/Guard/Transaction/MyTransactions";

const accessToken = Cookies.get('accessToken');
const refreshToken = Cookies.get('refreshToken');

if (accessToken && refreshToken) {
  setAuthToken(accessToken, refreshToken);
}

export default () => {
  const routes = [
    { path: '/', element: <Home /> },
    { path: '/page/:slug', element: <OrderPages /> },
    { path: '/invoice/:invoice', element: <InvoicePage /> },
    { path: '/:username', element: <ProductsAuthor /> },
    {
      element: <Creator />,
      children: [
        { path: '/profile', element: <MyProfile /> },
        { path: '/products', element: <MyProduct /> },
        { path: '/add-product', element: <AddProduct /> },
        { path: '/coupons', element: <MyCoupons /> },
        { path: '/add-coupon', element: <AddCoupon /> },
        { path: '/transactions', element: <MyTransactions /> },
      ],
    },
  ];
  const dispatch = useDispatch()
  const checkUser = async () => {
    try {
      const response = await API.get("/cek-user");

      if (response.status === 404) {
        // Swal.fire(
        //   'Oops...',
        //   'Session Expired!',
        //   'error'
        // )
        return dispatch(authError);
      }

      let payload = response.data.data.user;

      dispatch(auth(payload));
    } catch (error) {
      // Swal.fire(
      //   'Oops...',
      //   'Session Expired!',
      //   'error'
      // );
      dispatch(authError);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <>
    <Header />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {route.children &&
              route.children.map((childRoute, childIndex) => (
                <Route key={childIndex} path={childRoute.path} element={childRoute.element} />
              ))}
          </Route>
        ))}
      </Routes>
      <Footer />
    </>
  );
};