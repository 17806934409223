import {Link} from "react-router-dom"
import {useSelector} from "react-redux"
import Menu from "./Menu";
import AuthMenu from "./AuthMenu";
import {useLocation} from 'react-router-dom';

const Header = () => {
    const { user } = useSelector((state) => state);
    const location = useLocation();

    const isHomepage = location.pathname === '/';

    if (!isHomepage && !user.isLogin) {
      return null;
    }

    return (
    <header className="flex flex-row items-center justify-between bg-[#caf0f8] py-4 px-6 border-b-2 border-[#0077b6]">
      <div className="flex items-center">
        <Link className="text-[#0077b6] hover:text-[#023e8a] hover:no-underline text-2xl md:text-3xl font-extrabold" to="/">
          ShoSho <span className="bg-[#48cae4] text-[#023e8a] text-sm md:text-base font-semibold mr-1 px-2.5 py-1 rounded ml-1">PRO</span>
        </Link>
      </div>
      <nav className="flex items-center space-x-4">
        {user.isLogin ? <Menu /> : <AuthMenu />}
      </nav>
    </header>
    )
}

export default Header;