import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../store/actions/lodaing"
import {API} from "../../../config/api";
import {Link} from "react-router-dom";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import Pagination from "../../../components/Pagination/Pagination";
import TableGeneral from "../../../components/Table/TableGeneral";
import ModalConfirm from "../../../components/Modal/ModalConfirm";
import Search from "../../../components/Search/Search";
import {convertToRupiah} from "../../../utils/rupiah";
import {getFullTime} from "../../../utils/date"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSquareCheck, faSquareXmark} from '@fortawesome/free-solid-svg-icons';

export default () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [transactions, setTransactions] = useState([]);
    const [isError, setIsError] = useState(false);
    const [idUpdate, setIdUpdate] = useState({
        id: null,
        status: ""
    });
    const [confirmUpdate, setConfirmUpdate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [msg, setMsg] = useState({
        error: false,
        text: ""
    });
    const [search, setSearch] = useState("");
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state) => state);

    const headers = ["nomor_transaksi", "invoice_number" ,"product", "name", "email", "phone", "address", "status", "order_time", "is_discount", "coupon_code", "price", "qty", "total", "action"];
    const headerLabels = ["No", "Nomor Invoice", "Produk", "Nama", "Email", "No Hp", "Alamat", "Status", "Tanggal Order", "Pakai Kupon?", "Kode Kupon", "Harga", "Jumlah", "Total", "Action" ];
    const headerStyles = ["w-[40px]", "w-[150px]", "w-[150px]", "w-[150px]", "w-[200px]","w-[150px]", "w-[300px]", "w-[100px]","w-[200px]"];

    const getTransactions = async () => {
        try {
            dispatch(setLoading(true));
            const resp = await API.get(`/transaction/all-my?page=${currentPage}&limit=7&search=${search}`);
            const value = resp.data.data;
            let hasil = [];
            for (let i = 0; i < value.length; i++) {
                hasil.push({
                    invoice_number: <Link to={`/invoice/${value[i].invoice_number}`}>{value[i].invoice_number}</Link>,
                    product: value[i].title,
                    price: convertToRupiah(value[i].price),
                    is_discount: value[i].is_discount == "1" ? "Yes" : "No",
                    coupon_code: value[i].coupon_code,
                    status: (value[i].status == "pending") ? <p className="font-semibold text-orange-500 mb-0">Pending</p> : (value[i].status == "approved") ? <p className="font-semibold text-green-500 mb-0">Approved</p> : <p className="font-semibold text-red-500 mb-0">Rejected</p>,
                    order_time: getFullTime(value[i].createdAt),
                    name: value[i].name,
                    email: value[i].email,
                    phone: value[i].phone,
                    address: value[i].address,
                    qty: value[i].qty,
                    total: convertToRupiah(value[i].total),
                    action: (value[i].status == "pending") ? 
                    [
                        <FontAwesomeIcon
                            key={value[i].id+"approve"}
                            icon={faSquareCheck}
                            className="cursor-pointer text-2xl m-1 text-green-600 hover:text-green-700"
                            title="Approve pesanan"
                            onClick={() => handleUpdate(value[i].id, "approved")}
                        />,
                        <FontAwesomeIcon
                            key={value[i].id+"reject"}
                            icon={faSquareXmark}
                            className="cursor-pointer text-2xl m-1 text-red-600 hover:text-red-700"
                            title="Reject pesanan"
                            onClick={() => handleUpdate(value[i].id, "rejected")}
                        />
                    ] : (value[i].status == "approved") ? [
                        <FontAwesomeIcon
                            key={value[i].id+"approved"}
                            icon={faSquareCheck}
                            className="text-grey-300 text-2xl m-1"
                        />,
                    ] : [
                        <FontAwesomeIcon
                            key={value[i].id+"rejected"}
                            icon={faSquareXmark}
                            className="text-grey-300 text-2xl m-1"
                        />,
                    ]
                })
            }
            hasil.forEach((objek, index) => {
                objek.nomor_transaksi = (index + 1).toString();
            });
            setTransactions(hasil);
            setTotalPage(resp.data.totalPages);
            dispatch(setLoading(false));
        } catch(err) {
            dispatch(setLoading(true));
            setIsError(true);
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        getTransactions()
    }, [currentPage])

    const openModal = () => {
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleUpdate = (id, val) => {
        setIdUpdate({
            id: id,
            status: val
        });
        openModal();
    };

    const updateStatus = async (id, val) => {
        try {
            await API.patch(`/transaction/update/${id}`, { status: val });
            setMsg({ error: false, text: "Sukses update transaksi" });
            getTransactions();
        } catch (err) {
            setMsg({
                error: true,
                text: "Ada kesalahan server, coba lagi nanti",
            });
        }
        setTimeout(() => {
            setMsg({ error: false, text: "" });
        }, 2000);
    };

    useEffect(() => {
        if (confirmUpdate) {
            closeModal();
            updateStatus(idUpdate.id, idUpdate.status);
            setConfirmUpdate(null);
        }
    }, [confirmUpdate]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const nextPage = () => {
        if (currentPage < totalPage) {
            setCurrentPage(currentPage + 1);
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const handleSearch = () => {
        setCurrentPage(1);
        getTransactions();
    }

    return (
        <div className="creatorContainer">
            <h1>Daftar Transaksi Kamu</h1>
            <hr className="my-3"/>
            <Search 
                handleSearchChange={handleSearchChange} 
                handleSearch={handleSearch}
                placeholder="Cari Transaksi"
                note="Cari berdasarkan nomor invoice, nama pembeli atau nama produk"
            />
            <hr className="my-3"/>
            {msg.text && (
                <div className={`p-1 my-3 rounded border ${msg.error ? "border-red-500 bg-red-100" : "border-green-500 bg-green-100"}`}>
                    <p className={`text-center mb-0 font-bold ${msg.error ? "text-red-500" : "text-green-500"}`}>{msg.text}</p>
                </div>
            )}
            
            {isError ? (
            <div>
                <p className="text-gray-500">Belum ada transaksi.</p>
            </div>
            ) : (
                <TableGeneral
                data={transactions}
                headers={headers}
                headerLabels={headerLabels}
                headerStyles={headerStyles}
            />
            )}

            <Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                handlePageChange={handlePageChange}
                prevPage={prevPage}
                nextPage={nextPage}
            />

            <ModalConfirm
                setConfirm={setConfirmUpdate}
                isOpen={isModalOpen}
                onClose={closeModal}
                action={`Apakah anda yakin ingin me ${idUpdate.status == "approved" ? "approve" : "reject"} transaksi ini?`}
            />

            {isLoading && <LoadingOverlay/>}
        </div>
    )

}