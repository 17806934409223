import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../store/actions/lodaing";
import {useParams} from "react-router-dom";
import {API} from "../config/api";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import Pagination from "../components/Pagination/Pagination";
import ProductCard from "../components/Card/ProductCard";
import ProfileCard from "../components/Card/ProfileCard";
import NotFound from "../components/Loading/NotFound";

export default () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [products, setProducts] = useState([]);
    const [profile, setProfile] = useState({});
    const [isError, setIsError] = useState(false);
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state) => state);
    const { username } = useParams()

    const getProducts = async () => {
        try {
            dispatch(setLoading(true));
            const product = await API.get(`/product/by/${username}?page=${currentPage}&limit=5`);
            const profile = await API.get(`/user/get/${username}`);
            setProducts(product.data.data);
            setProfile(profile.data.data);
            setTotalPage(product.data.totalPages)
            dispatch(setLoading(false));
        } catch(err) {
            dispatch(setLoading(true));
            setIsError(true);
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        getProducts()
    }, [currentPage])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const nextPage = () => {
        if (currentPage < totalPage) {
            setCurrentPage(currentPage + 1);
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    if (isError) {
        return <NotFound />;
    }

    return (
        <div className="my-10 max-w-2xl rounded shadow-lg mx-auto p-4 bg-blue-100">
            <ProfileCard 
                profile={profile} 
            />
            <ProductCard 
                products={products} 
            />
            {totalPage > 1 && 
            <Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                handlePageChange={handlePageChange}
                prevPage={prevPage}
                nextPage={nextPage}
            />
            }
            {(profile.saweria || profile.trakteer) &&
            <div className="mt-5">
                <p className="text-xl font-bold text-center">Support :</p>
                {profile.saweria &&
                <div 
                    className="w-full border-2 border-black rounded-lg text-center bg-orange-200 my-2 cursor-pointer hover:bg-orange-300 hover:shadow-md py-4"
                    onClick={() => openInNewTab(`https://saweria.co/${profile.saweria}`)}
                >
                    <span className="font-semibold text-lg">Support via Saweria</span>
                </div>
                }
                {profile.trakteer &&
                <div 
                    className="w-full border-2 border-black rounded-lg text-center bg-red-200 my-2 cursor-pointer hover:bg-red-300 hover:shadow-md py-4"
                    onClick={() => openInNewTab(`https://trakteer.id/${profile.trakteer}/tip`)}
                >
                    <span className="font-semibold text-lg">Support via Trakteer</span>
                </div>
                }
            </div>
            }
            {isLoading && <LoadingOverlay/>}
        </div>
    )
}