import {useState} from "react"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router-dom"
import {logout} from "../../store/actions/user"
import {API} from "../../config/api"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faTags, faCartShopping, faBagShopping, faPowerOff, faUser} from '@fortawesome/free-solid-svg-icons';

const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const router = useNavigate()

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const menus = [
        {
            name: "Profile",
            link: "profile",
            icon: faUser
        },
        {
            name: "Products",
            link: "products",
            icon: faBagShopping
        },
        {
            name: "Coupons",
            link: "coupons",
            icon: faTags
        },
        {
            name: "Transactions",
            link: "transactions",
            icon: faCartShopping
        }
    ];

    const handleMove = (link) => {
        router(`/${link}`);
        toggleDropdown()
    }

    const handleLogout = () => {
        API.get('/logout');
        dispatch(logout());
        router('/');
    }

    return (
        <> 
        <div className="relative inline-block text-left z-10">
        <button
            id="dropdownDefaultButton"
            className="menuButton"
            type="button"
            onClick={toggleDropdown}
        >
            <FontAwesomeIcon
                icon={faBars}
            />
        </button>
        {isOpen && (
            <div
            id="dropdown"
            className="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg"
            >
                {menus.map((item, index) => (
                <ul key={index} className="text-sm text-gray-700">
                    <li>
                        <button className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900" onClick={() => handleMove(item.link)}>
                        <FontAwesomeIcon
                            icon={item.icon}
                            className="mr-1"
                        />
                        {item.name}
                        </button>
                    </li>
                </ul>
                ))}
                <ul className="text-sm text-gray-700">
                    <li>
                        <button className="block w-full px-4 py-2 text-sm text-left text-red-600 hover:bg-gray-100 hover:text-gray-900" onClick={handleLogout}>
                            <FontAwesomeIcon
                                icon={faPowerOff}
                                className="mr-1"
                            />
                            Logout
                        </button>
                    </li>
                </ul>
            </div>
        )}
        </div>
        </>
    )
}

export default Menu;