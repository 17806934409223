import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <>
        <div className="mt-5 py-2 text-center text-xs">
            <span>Copyright 2023 &#169; <Link to="/">Shouhin Shoukai Pro</Link></span>
        </div>
        </>
    )
}

export default Footer;