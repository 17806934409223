import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {API} from "../../../config/api";
import Swal from "sweetalert2";

export default () => {
    const [form, setForm] = useState({
        title: "",
        slug: "",
        description: "",
        price: "",
        stocks: "",
        product_image: "https://res.cloudinary.com/kejepangan/image/upload/v1646118001/OnlineCinemaAhsan/prevtumb_n9aucl.png",
        });
    const [msg, setMsg] = useState({});
    const [preview, setPreview] = useState("");

    const router = useNavigate();

    const generateSlug = (title) => {
        const slug = title
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^a-z0-9-]/g, "");
        return slug;
    };
    
    const handleOnChange = (e) => {
        const { name, type, value, files } = e.target;
        if (type === "file") {
            let url = URL.createObjectURL(files[0]);
            setPreview(url);
        }

        if (name === "title") {
            const slugValue = generateSlug(value);
            setForm((prevForm) => ({
                ...prevForm,
                [name]: value,
                slug: slugValue
            }));
        } else {
            setForm((prevForm) => ({
            ...prevForm,
                [name]: type === "file" ? files : value
            }));
        }
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        try {
            const config = {
                headers: {
                "Content-type": "multipart/form-data",
                },
            };
        
            const formData = new FormData();
            formData.set("title", form.title);
            formData.set("slug", form.slug);
            formData.set("product_image", form.product_image[0], form.product_image[0].name);
            formData.set("description", form.description);
            formData.set("price", form.price);
            formData.set("stocks", form.stocks);
        
            await API.post("/product/add", formData, config);
        
            setMsg({
                error: false,
                text: "Produk kamu sudah berhasil ditambahkan",
            });

            Swal.fire("Good job!", "Produk Berhasil Ditambahkan", "success");
        
            setTimeout(() => {
                router("/products");
            }, 1500);
        } catch (err) {
            console.log(err);
            setMsg({
                error: true,
                text: "Ada kesalahan server, coba lagi nanti",
            });
            }
        };
    
    return (
        <>
        <div className="mainContainer">
        <h1>Add Product</h1>
        <hr className="my-3" />
        {msg.text && (
            <div className={`p-1 my-3 rounded border ${msg.error ? "border-red-500 bg-red-100" : "border-green-500 bg-green-100"}`}>
                <p className={`text-center mb-0 font-bold ${msg.error ? "text-red-500" : "text-green-500"}`}>{msg.text}</p>
            </div>
        )}
        <form onSubmit={handleOnSubmit}>
            <label htmlFor="title">Nama Produk:</label>
            <input
                type="text"
                name="title"
                id="title"
                onChange={handleOnChange}
                value={form.title}
                className="mainInput"
                placeholder="Nama Produk"
                required
            />
            <input
                type="text"
                name="slug"
                id="slug"
                value={form.slug}
                className="mainInput border text-sky-700 placeholder-sky-700 border-sky-700 text-sm italic"
                placeholder="Slug"
                readOnly
            />
            <div className="my-3">
            <p>Foto Produk:</p>
            <div className="my-5">
            {preview ? (
                <img src={preview} className="img-fluid rounded" width="20%" />
            ) : (
                <img
                src={form.product_image}
                className="img-fluid rounded"
                width="25%"
                />
            )}
            </div>
            <label
                htmlFor="product_image"
                className="flex flex-row bg-[#0096c7] hover:bg-[#0077b6] w-44 justify-center items-center cursor-pointer rounded-md"
                >
                <p style={{ marginTop: "19px", color: "#fff" }}>Attach Image</p>
                <img
                    src="/Frame1.svg"
                    width={15}
                    height={15}
                    style={{ marginLeft: "5px" }}
                />
                </label>
                <input
                    type="file"
                    id="product_image"
                    onChange={handleOnChange}
                    name="product_image"
                    required
                    hidden
                />
            </div>
            <label htmlFor="description">Deskripsi Produk:</label>
            <textarea
                type="text"
                name="description"
                id="description"
                onChange={handleOnChange}
                value={form.description}
                className="mainInput resize-none h-28"
                placeholder="Deskripsi Produk"
            ></textarea>
            <label htmlFor="price">Harga Produk:</label>
            <div className="flex flex-row items-center">
            <span className="text-lg mr-2 text-slate-500">Rp.</span>
            <input
                type="number"
                name="price"
                id="price"
                onChange={handleOnChange}
                value={form.price}
                className="priceInput"
                placeholder="Harga Produk"
                required
            />
            </div>
            <label htmlFor="stocks">Jumlah Stok:</label>
            <input
                type="number"
                name="stocks"
                id="stocks"
                onChange={handleOnChange}
                value={form.stocks}
                className="mainInput"
                placeholder="Jumlah Stok"
                required
            />
            <button className="mainButton" type="submit">Add Product</button>
        </form>
        </div>
        </>
    )
}