const TableGeneral = ({headers, data, headerLabels, headerStyles}) => {
    return (
        <div className="w-full overflow-x-auto">
        <table className="table-fixed w-full">
        <thead>
            <tr>
            {headerLabels.map((label, index) => (
                <th key={index} className={headerStyles[index] || "w-[120px]"}>{label}</th>
            ))}
            </tr>
        </thead>
        <tbody>
            {data.map((item, index) => (
            <tr key={index}>
                {headers.map((header, index) => (
                    <td key={index}>{item[header]}</td>
                ))}
            </tr>
            ))}
        </tbody>
        </table>
        </div>
    );
}

export default TableGeneral;