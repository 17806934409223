import {useState} from "react";
import {useDispatch} from "react-redux"
import {auth} from "../../store/actions/user"
import {API, setAuthToken} from "../../config/api";
import PasswordInput from "./PasswordInput";

const Login = ({onClose}) => {
    const [message, setMessage] = useState({
        active: false,
        status: "",
        message: "",
    });
    const [form, setForm] = useState({
        username: "",
        password: "",
    });

    const dispact = useDispatch();

    const handleOnChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleOnSubmit = async (e) => {
        try {
            e.preventDefault();
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            const body = JSON.stringify(form);
            const response = await API.post("/login", body, config);
            const result = response.data.data;
            setAuthToken(result.user.accessToken, result.user.refreshToken);
            if (response.data.status == "success") {
                setMessage({
                    active: true,
                    status: "success",
                    message: "Login Berhasil"
                });
                setForm({
                    username: "",
                    password: "",
                });
                setTimeout(() => {
                    dispact(auth(result.user))
                    onClose()
                }, 1000);
            }
            } catch (err) {
            setMessage({
                active: true,
                status: "failed",
                message: "Username atau password tidak cocok"
            });
        }
    };

    return (
        <>
        {(message.active && message.status == 'success') ? (
            <div className="bg-green-100 p-1 mb-2 rounded border border-green-500">
            <p className="text-green-500 text-center mb-0">{message.message}</p>
            </div>
        ) : (message.active && message.status == 'failed') ? (
            <div className="bg-red-100 p-1 mb-2 rounded border border-red-500">
                <p className="text-red-500 text-center mb-0">{message.message}</p>
            </div>
        ) : (<div></div>)}
        <h1 className="mb-3">Login</h1>
        <form onSubmit={handleOnSubmit}>
        <input
            className="mainInput"
            name="username"
            id="username"
            type="text"
            onChange={handleOnChange}
            placeholder="Username"
            required
        />
        <PasswordInput 
            valPassword={form.password} 
            handleOnChange={handleOnChange}
            identify="password"
        />
        <button type="submit" className="mainButton">Login</button>
        </form>
        </>
    )
}

export default Login;