export const login = (data) => ({
    type: "LOGIN_SUCCESS",
    payload: data
});

export const auth = (data) => ({
    type: "USER_SUCCESS",
    payload: data
});

export const logout = () => ({
    type: "LOGOUT"
});

export const authError = () => ({
    type: "AUTH_ERROR"
});