import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../store/actions/lodaing"
import {useNavigate} from "react-router-dom";
import {API} from "../../../config/api";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import SuggestionBox from "../../../components/Input/SuggestionBox";
import Swal from "sweetalert2";
import moment from "moment";

export default () => {
    const [products, setProducts] = useState([]);
    const [isOnSubmit, setIsOnSubmit] = useState(false);
    const [idProduct, setIdProduct] = useState(null);
    const [productSelected, setProductSelected] = useState(false);
    const [isMinOrder, setIsMinOrder] = useState(false);
    const [valMinOrder, setValMinOrder] = useState(null);
    const [isExpCoupon, setIsExpCoupon] = useState(false);
    const [valExpCoupon, setValExpCoupon] = useState({
        start_coupon: null,
        exp_coupon: null
    });
    const [form, setForm] = useState({
        title: "",
        discount_type: null,
        value: "",
        coupon_code: "",
    });
    const [msg, setMsg] = useState({});
    const dispatch = useDispatch();
    const router = useNavigate();
    const { isLoading } = useSelector((state) => state);

    const getProducts = async () => {
        try {
            dispatch(setLoading(true));
            const resp = await API.get(`/coupon/check-product`);
            setProducts(resp.data.data);
            dispatch(setLoading(false));
        } catch(err) {
            dispatch(setLoading(true));
            console.log("Gagal fetch data");
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        getProducts()
    },[]);

    const handleOnChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }

    const handleOnSubmit = async(e) => {
        e.preventDefault();
        try {
            setIsOnSubmit(true);
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };

            const dataCoupon = {
                title: form.title,
                id_product: idProduct,
                discount_type: form.discount_type,
                value: form.value,
                coupon_code: form.coupon_code,
                min_order: isMinOrder ? valMinOrder : null,
                start_coupon: isExpCoupon ? valExpCoupon.start_coupon : null,
                exp_coupon: isExpCoupon ? valExpCoupon.exp_coupon : null
            }

            await API.post("/coupon/add", dataCoupon, config);
        
            setMsg({
                error: false,
                text: "Kupon kamu sudah berhasil ditambahkan",
            });

            Swal.fire("Good job!", "Kupon Berhasil Ditambahkan", "success");

            setIsOnSubmit(false);

            setTimeout(() => {
                router("/coupons");
            }, 1500);
        } catch(err) {
            console.log(err);
            setMsg({
                error: true,
                text: "Ada kesalahan server, coba lagi nanti",
            });
        }
    }

    const handleSelect = (itemId, val) => {
        setIdProduct(itemId);
        setProductSelected(val);
    };

    const handleMinOrderCheckboxChange = () => {
        if(valMinOrder) {
            setValMinOrder(null);
            setIsMinOrder(!isMinOrder);
        }
        setIsMinOrder(!isMinOrder);
    };

    const handleMinOrderChange = (e) => {
        setValMinOrder(e.target.value);
    }

    const handleExpCheckboxChange = () => {
        if(valExpCoupon) {
            setValExpCoupon({
                start_coupon: null,
                exp_coupon: null
            });
            setIsExpCoupon(!isExpCoupon);
        }
        setIsExpCoupon(!isExpCoupon);
    };

    const handleExpChange = (e) => {
        setValExpCoupon({
            ...valExpCoupon,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <div className="mainContainer">
            <h1>Add Coupon</h1>
            <hr className="my-3"/>
            {msg.text && (
                <div className={`p-1 my-3 rounded border ${msg.error ? "border-red-500 bg-red-100" : "border-green-500 bg-green-100"}`}>
                    <p className={`text-center mb-0 font-bold ${msg.error ? "text-red-500" : "text-green-500"}`}>{msg.text}</p>
                </div>
            )}
            <form onSubmit={handleOnSubmit}>
            <SuggestionBox suggestions={products} handleSelect={handleSelect} placeHolder="Cari produk..." label="Pilih Produk:" selected={productSelected} />
            {/* Title */}
            <label htmlFor="title">Nama Kupon:</label>
            <input
                type="text"
                name="title"
                id="title"
                onChange={handleOnChange}
                value={form.title}
                className="mainInput"
                placeholder="Nama Kupon"
                required
            />
            {/* Tipe Kupon */}
            <label htmlFor="discount_type">Tipe Kupon:</label>
            <select
                name="discount_type"
                id="discount_type"
                onChange={handleOnChange}
                className="mainInput"
                required
            >
                <option value="1">
                    Diskon Presentase
                </option>
                <option value="2">
                    Diskon Nominal Fix
                </option>
            </select>
            {/* Value */}
            <label htmlFor="value">Nama Kupon:</label>
            <input
                type="number"
                name="value"
                id="value"
                onChange={handleOnChange}
                value={form.value}
                className="mainInput"
                placeholder="Value Kupon"
                required
            />
            {/* Coupon Code */}
            <label htmlFor="coupon_code">Kode Kupon:</label>
            <input
                type="text"
                name="coupon_code"
                id="coupon_code"
                onChange={handleOnChange}
                value={form.coupon_code}
                className="mainInput"
                placeholder="Kode Kupon"
                required
            />
            {/* Min Order */}
            <hr className="my-3"/>
            <label htmlFor="minimal_order">Minimal Order:</label>
            <div className="flex flex-row">
                <p className={isMinOrder ? "flex-[10%] my-auto text-xl" : "flex-[10%] my-auto text-xl text-red-300"}>Rp.</p>
                <input 
                type="number" 
                id="minimal_order"
                placeholder="Nominal minimal diskon" 
                value={valMinOrder ? valMinOrder : ""}
                onChange={handleMinOrderChange}
                className={isMinOrder ? "priceInput flex-[90%]" : "priceInput border text-red-300 placeholder-red-300 border-red-300 flex-[90%]"} 
                disabled={!isMinOrder} />
            </div>
            <label>
                    <input
                    type="checkbox"
                    checked={isMinOrder}
                    onChange={handleMinOrderCheckboxChange}                
                    />
                    {" "} Tambahkan minimal order
            </label>
            {/* Expired Coupon */}
            <hr className="my-3"/>
            <div className="flex flex-col md:flex-row">
                <div className="flex-[45%] mr-1">
                    <p className="m-0">Waktu kupon berlaku</p>
                    <input 
                    type="datetime-local"
                    id="start_coupon"
                    value={valExpCoupon ? moment(valExpCoupon.start_coupon).format("YYYY-MM-DDTkk:mm") : ""}
                    onChange={handleExpChange}
                    name="start_coupon"
                    className={isExpCoupon ? "priceInput" : "priceInput border text-red-300 placeholder-red-300 border-red-300"} 
                    disabled={!isExpCoupon} />
                </div>
                <div className="flex-[45%] mr-1">
                    <p className="m-0">Waktu kupon expired</p>
                    <input 
                    type="datetime-local"
                    id="exp_coupon"
                    value={valExpCoupon ? moment(valExpCoupon.exp_coupon).format("YYYY-MM-DDTkk:mm") : ""}
                    onChange={handleExpChange}
                    name="exp_coupon"
                    className={isExpCoupon ? "priceInput" : "priceInput border text-red-300 placeholder-red-300 border-red-300"} 
                    disabled={!isExpCoupon} />
                </div>
            </div>
            <label>
                    <input
                    type="checkbox"
                    checked={isExpCoupon}
                    onChange={handleExpCheckboxChange}                
                    />
                    {" "} Tambahkan masa aktif kupon
            </label>
        <hr className="my-3"/>
        <button type="submit" className={isOnSubmit ? "loadingButton" : "mainButton"} disabled={isOnSubmit}>
            {isOnSubmit ? "Tunggu Sebentar" : "Add Coupon"}
        </button>
        </form>
        {isLoading && <LoadingOverlay />}
        </div>
    )
}