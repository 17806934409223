import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../store/actions/lodaing";
import {API} from "../../../config/api";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import ProfileData from "../../../components/Table/ProfileData";
import ChangePassword from "../../../components/Auth/ChangePassword";
import ModalProfile from "../../../components/Modal/ModalProfile";
import NotFound from "../../../components/Loading/NotFound";

export default () => {
    const [profile, setProfile] = useState({});
    const [isError, setIsError] = useState(false);
    const [msg, setMsg] = useState({
        error: false,
        text: ""
    })
    const [stateModal, setStateModal] = useState({
        changePassword: false,
        editProfile: false,
        editSocmed: false,
        changePhotoProfile: false,
    })
    const [formProfile, setFormProfile] = useState({
        full_name: "",
        username: "",
        email: "",
        phone: "",
        bio_creator: "",
    })
    const [formSocmed, setFormSocmed] = useState({
        tiktok: "",
        youtube: "",
        facebook: "",
        instagram: "",
        twitter: "",
        saweria: "",
        trakteer: "",
    })
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state);

    const openModal = (modalName) => {
        setStateModal({ ...stateModal, [modalName]: true });
    };
    
    const closeModal = (modalName) => {
        setStateModal({ ...stateModal, [modalName]: false });
    };

    const getProfile = async() => {
        try {
            dispatch(setLoading(true));
            const resp = await API.get(`/user/my`);
            setProfile(resp.data.data);
            setFormProfile({
                full_name: resp.data.data.full_name,
                username: resp.data.data.username,
                email: resp.data.data.email,
                phone: resp.data.data.phone,
                bio_creator: resp.data.data.bio_creator,
            })
            setFormSocmed({
                tiktok: resp.data.data.tiktok,
                youtube: resp.data.data.youtube,
                facebook: resp.data.data.facebook,
                instagram: resp.data.data.instagram,
                twitter: resp.data.data.twitter,
                saweria: resp.data.data.saweria,
                trakteer: resp.data.data.trakteer,
            })
            dispatch(setLoading(false));
        } catch(err) {
            dispatch(setLoading(true));
            setIsError(true);
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        getProfile();
    }, []);

    const handleOnChangeEditProfile = (e) => {
        setFormProfile({
            ...formProfile,
            [e.target.name]: e.target.value,
        })
    }

    const handleOnChangeEditSocmed = (e) => {
        setFormSocmed({
            ...formSocmed,
            [e.target.name]: e.target.value,
        })
    }

    const handleOnSubmitEditProfile = async (e) => {
        e.preventDefault();
        try {
            const dataProfile = {
                full_name: formProfile.full_name,
                username: formProfile.username,
                email: formProfile.email,
                phone: formProfile.phone,
                bio_creator: formProfile.bio_creator,
            }
            await API.patch(`/user/update`, dataProfile);
            setMsg({
                error: false,
                text: "Sukses update data profile"
            })
            setFormProfile({
                full_name: "",
                username: "",
                email: "",
                phone: "",
            })
        } catch(err) {
            setMsg({
                error: true,
                text: "Gagal update data profile"
            })
        }
        closeModal('editProfile');
        getProfile();
        setTimeout(() => {
            setMsg({ error: false, text: "" });
        }, 3000);
    }

    const handleOnSubmitEditSocmed = async (e) => {
        e.preventDefault();
        try {
            const dataSocmed = {
                facebook: formSocmed.facebook,
                twitter: formSocmed.twitter,
                instagram: formSocmed.instagram,
                youtube: formSocmed.youtube,
                tiktok: formSocmed.tiktok,
                saweria: formSocmed.saweria,
                trakteer: formSocmed.trakteer,
            }
            await API.patch(`/user/update`, dataSocmed);
            setMsg({
                error: false,
                text: "Sukses update data socmed"
            })
            setFormSocmed({
                tiktok: "",
                youtube: "",
                facebook: "",
                instagram: "",
                twitter: "",
                saweria: "",
                trakteer: "",
            })
        } catch(err) {
            setMsg({
                error: true,
                text: "Gagal update data profile"
            })
        }
        closeModal('editSocmed');
        getProfile();
        setTimeout(() => {
            setMsg({ error: false, text: "" });
        }, 3000);
    }

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const dataProfile = [
        {
            className: "mainInput",
            name: "full_name",
            id: "full_name",
            type: "text",
            title: "Nama Lengkap:",
            value: formProfile.full_name,
            placeholder: "Nama Anda",
            required: false,
            disabled: false,
        },
        {
            className: "mainInput",
            name: "username",
            id: "username",
            type: "text",
            title: "Username:",
            value: formProfile.username,
            placeholder: "Username Anda",
            required: false,
            disabled: false,
        },
        {
            className: "mainInput",
            name: "email",
            id: "email",
            type: "email",
            title: "Email:",
            value: formProfile.email,
            placeholder: "Email Anda",
            required: false,
            disabled: false,
        },
        {
            className: "mainInput",
            name: "phone",
            id: "phone",
            type: "number",
            title: "Nomor HP:",
            value: formProfile.phone,
            placeholder: "Nomor HP Anda",
            required: false,
            disabled: false,
        },
        {
            className: "mainInput",
            name: "bio_creator",
            id: "bio_creator",
            type: "text",
            title: "Creator Bio:",
            value: formProfile.bio_creator ? formProfile.bio_creator : "",
            placeholder: "Creator Bio Anda",
            required: false,
            disabled: false,
        },
    ]

    const dataSocmed = [
        {
            className: "mainInput",
            name: "facebook",
            id: "facebook",
            type: "text",
            title: "Facebook:",
            value: formSocmed.facebook ? formSocmed.facebook : "",
            placeholder: "Facebook Anda",
            required: false,
            disabled: false,
        },
        {
            className: "mainInput",
            name: "instagram",
            id: "instagram",
            type: "text",
            title: "Instagram:",
            value: formSocmed.instagram ? formSocmed.instagram : "",
            placeholder: "Instagram Anda",
            required: false,
            disabled: false,
        },
        {
            className: "mainInput",
            name: "twitter",
            id: "twitter",
            type: "text",
            title: "Twitter:",
            value: formSocmed.twitter ? formSocmed.twitter : "",
            placeholder: "Twitter Anda",
            required: false,
            disabled: false,
        },
        {
            className: "mainInput",
            name: "youtube",
            id: "youtube",
            type: "text",
            title: "Youtube:",
            value: formSocmed.youtube ? formSocmed.youtube : "",
            placeholder: "Youtube Anda",
            required: false,
            disabled: false,
        },
        {
            className: "mainInput",
            name: "tiktok",
            id: "tiktok",
            type: "text",
            title: "Tiktok:",
            value: formSocmed.tiktok ? formSocmed.tiktok : "",
            placeholder: "Tiktok Anda",
            required: false,
            disabled: false,
        },
        {
            className: "mainInput",
            name: "saweria",
            id: "saweria",
            type: "text",
            title: "Saweria:",
            value: formSocmed.saweria ? formSocmed.saweria : "",
            placeholder: "Saweria Anda",
            required: false,
            disabled: false,
        },
        {
            className: "mainInput",
            name: "trakteer",
            id: "trakteer",
            type: "text",
            title: "Trakteer:",
            value: formSocmed.trakteer ? formSocmed.trakteer : "",
            placeholder: "Trakteer Anda",
            required: false,
            disabled: false,
        },
    ]

    if (isError) {
        return <NotFound />
    }

    return (
        <div className="creatorContainer">
            <div>
                <h1>Profil {profile.full_name}</h1>
                <span onClick={() => openInNewTab(`/${profile.username}`)} className="text-xs italic cursor-pointer">View Profile as Public</span>
                <hr className="my-3" />

                {msg.text && (
                <div className={`p-1 my-3 rounded border ${msg.error ? "border-red-500 bg-red-100" : "border-green-500 bg-green-100"}`}>
                    <p className={`text-center mb-0 font-bold ${msg.error ? "text-red-500" : "text-green-500"}`}>{msg.text}</p>
                </div>
                )}

                <ProfileData profile={profile} openModal={openModal} />
                <hr className="my-3" />
            </div>
            <ChangePassword isOpen={stateModal.changePassword} onClose={() => closeModal('changePassword')} />
            <ModalProfile isOpen={stateModal.editProfile} onClose={() => closeModal('editProfile')} data={dataProfile} handleOnChange={handleOnChangeEditProfile} handleOnSubmit={handleOnSubmitEditProfile} header="Edit Profile" />
            <ModalProfile isOpen={stateModal.editSocmed} onClose={() => closeModal('editSocmed')} data={dataSocmed} handleOnChange={handleOnChangeEditSocmed} handleOnSubmit={handleOnSubmitEditSocmed} header="Edit Social Media Information" />
            {isLoading && <LoadingOverlay />}
        </div>
    )
}