const Pagination = ({ currentPage, totalPage, handlePageChange, prevPage, nextPage }) => {

    const getPageNumbers = () => {
        const pages = [];
        if (totalPage <= 5) {
            for (let i = 1; i <= totalPage; i++) {
                pages.push(i);
        }
        } else {
            const startPage = Math.min(Math.max(currentPage - 1, 1), totalPage - 4);
            const endPage = Math.min(startPage + 4, totalPage);
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
        }
        return pages;
    };      

    return (
        <nav className="mt-4">
                <ul className="flex justify-center items-center space-x-4">
                    <li className={`inline-flex ${currentPage === 1 ? 'opacity-50' : 'cursor-pointer'}`}>
                    <button className="px-2 py-1 rounded-md bg-[#0077b6] text-white text-sm" disabled={currentPage === 1} onClick={prevPage}>
                        Prev
                    </button>
                    </li>

                    {getPageNumbers().map((pageNumber) => (
                        <li key={pageNumber} className={`inline-flex ${currentPage === pageNumber ? 'opacity-50' : 'cursor-pointer'}`}>
                            <button className={`px-2 py-1 rounded-md text-sm ${currentPage === pageNumber ? 'bg-gray-300' : 'bg-[#0077b6] text-white'}`} disabled={currentPage === pageNumber} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                            </button>
                        </li>
                    ))}

                    <li className={`inline-flex ${currentPage === totalPage ? 'opacity-50' : 'cursor-pointer'}`}>
                    <button className="px-2 py-1 rounded-md bg-[#0077b6] text-white text-sm" disabled={currentPage === totalPage} onClick={nextPage}>
                        Next
                    </button>
                    </li>
                </ul>
        </nav>
    )
}

export default Pagination;