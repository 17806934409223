import {useState} from 'react';
import {API} from "../../config/api";
import PasswordInput from "../Auth/PasswordInput";

const ChangePassword = ({ isOpen, onClose }) => {
    const [form, setForm] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    })
    const [msg, setMsg] = useState({
        error: false,
        text: ""
    })
    const [isSubmit, setIsSubmit] = useState(false);

    const handleOnChange = (e) => {
        setForm({
        ...form,
        [e.target.name]: e.target.value,
        });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsSubmit(true);
            if (form.newPassword === form.confirmNewPassword) {
                const data = {
                    old_password: form.oldPassword,
                    new_password: form.newPassword
                }
                const resp = await API.patch("/change-password", data);
                setForm({
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                })
                setMsg({
                    error: resp.data.status == "success" ? false : true,
                    text: resp.data.message
                })
                setTimeout(() => {
                    onClose();
                }, 3000);
            } else {
                setForm({
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                })
                setMsg({
                    error: true,
                    text: "Password baru tidak cocok"
                })
            }
            setIsSubmit(false);
        } catch(err) {
            setIsSubmit(true);
            setForm({
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: "",
            })
            setMsg({
                error: true,
                text: err.response.data.message
            })
            setIsSubmit(false);
        }
        setTimeout(() => {
            setMsg({ error: false, text: "" });
        }, 3000);
    }

    const closeModal = () => {
        onClose();
    };

return (
    <div className={`fixed z-50 inset-0 overflow-y-auto transition-opacity ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <div className="flex items-start justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={closeModal}></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:max-w-lg">
            <div className="bg-slate-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h4>Change Password</h4>

                {msg.text && (
                <div className={`p-1 my-3 rounded border ${msg.error ? "border-red-500 bg-red-100" : "border-green-500 bg-green-100"}`}>
                    <p className={`text-center mb-0 font-bold ${msg.error ? "text-red-500" : "text-green-500"}`}>{msg.text}</p>
                </div>
                )}

                <form onSubmit={handleOnSubmit}>
                    <span>Password Lama:</span>
                    <PasswordInput
                        valPassword={form.oldPassword}
                        handleOnChange={handleOnChange}
                        identify="oldPassword"
                    />
                    <span>Password Baru:</span>
                    <PasswordInput
                        valPassword={form.newPassword}
                        handleOnChange={handleOnChange}
                        identify="newPassword"
                    />
                    <span>Konfirmasi Password Baru:</span>
                    <PasswordInput
                        valPassword={form.confirmNewPassword}
                        handleOnChange={handleOnChange}
                        identify="confirmNewPassword"
                    />
                    <button type="submit" className={isSubmit ? "loadingButton" : "mainButton"} disabled={isSubmit}>{isSubmit ? "Loading..." : "Change Password"}</button>
                </form>
            </div>
        </div>
    </div>
    </div>
);
};

export default ChangePassword;