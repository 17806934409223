import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

const PasswordInput = ({valPassword, handleOnChange, identify}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    };

return (
    <div className="password-input">
        <input
            className="mainInput"
            type={showPassword ? "text" : "password"}
            name={identify}
            id={identify}
            onChange={handleOnChange}
            value={valPassword}
            placeholder="Password"
            required
        />
        <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="toggle-password-icon"
            onClick={togglePasswordVisibility}
        />
    </div>
    );
};

export default PasswordInput;