import "../../styles/spinner.css"

const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-80 z-50">
      <div className="flex flex-col items-center">
        <div className="spinner mb-4"></div>
        <p className="text-white">Loading...</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;