import { configureStore } from '@reduxjs/toolkit';
import userReducer from "./reducers/userReducer";
import loadingReducer from './reducers/loadingReducer';
import productReducer from './reducers/productReducer';

const store = configureStore({
    reducer: {
        user: userReducer,
        isLoading: loadingReducer,
        product: productReducer,
    },
});

export default store;