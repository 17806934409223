import axios from "axios";

export const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  // baseURL: "http://localhost:5005/api/",
  withCredentials: true,
});

export const setAuthToken = (accessToken, refreshToken) => {
  if (refreshToken && accessToken) {
    API.defaults.headers.common["Access-Token"] = `${accessToken}`;
    API.defaults.headers.common["Refresh-Token"] = `${refreshToken}`;
  } else {
    delete API.defaults.headers.common["Access-Token"];
    delete API.defaults.headers.common["Refresh-Token"];
  }
}

API.interceptors.response.use(
  response => (response), 
  error => (Promise.reject(error.response.data.err))
)
