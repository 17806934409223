import {useState} from "react";
import {API} from "../../config/api";
import PasswordInput from "./PasswordInput";

const Register = ({onClose}) => {
    const [message, setMessage] = useState({
        active: false,
        status: "",
        message: "",
    });
    const [form, setForm] = useState({
        email: "",
        full_name: "",
        username: "",
        phone: "",
        password: "",
    });

    const handleOnChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleOnSubmit = async (e) => {
        try {
            e.preventDefault();
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            const body = JSON.stringify(form);
            const response = await API.post("/register", body, config);
            if (response.data.status == "success") {
                setMessage({
                    active: true,
                    status: "success",
                    message: "Register Berhasil, Silahkan Login"
                });   
                setForm({
                    full_name: "",
                    email: "",
                    phone: "",
                    password: "",
                });
                setTimeout(() => {
                    onClose()
                }, 1000);
            }
            } catch (err) {
            setMessage({
                active: true,
                status: "failed",
                message: "Register Failed"
            });
        }
    };

    return (
        <>
        {(message.active && message.status == 'success') ? (
            <div className="bg-green-100 p-1 mb-2 rounded border border-green-500">
            <p className="text-green-500 text-center mb-0">{message.message}</p>
            </div>
        ) : (message.active && message.status == 'failed') ? (
            <div className="bg-red-100 p-1 mb-2 rounded border border-red-500">
                <p className="text-red-500 text-center mb-0">{message.message}</p>
            </div>
        ) : (<div></div>)}
        <h1 className="mb-3">Register</h1>
        <form onSubmit={handleOnSubmit}>
        <input
            className="mainInput"
            name="full_name"
            id="full_name"
            type="text"
            onChange={handleOnChange}
            placeholder="Nama Anda"
            required
        />
        <input
            className="mainInput"
            name="username"
            id="username"
            type="text"
            onChange={handleOnChange}
            placeholder="Username"
            required
        />
        <input
            className="mainInput"
            name="email"
            id="email"
            type="email"
            onChange={handleOnChange}
            placeholder="Email Anda"
            required
        />
        <input
            className="mainInput"
            name="phone"
            id="phone"
            type="number"
            onChange={handleOnChange}
            placeholder="Nomor HP Anda"
            required
        />
        <PasswordInput 
            valPassword={form.password} 
            handleOnChange={handleOnChange}
            identify="password"
        />
        <button type="submit" className="mainButton">Register</button>
        </form>
        </>
    )
}

export default Register;