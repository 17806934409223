const Input = ({ dataInput, handleOnChange }) => {
    return (
        <div>
            {dataInput.map((input, index) => (
                <div key={index}>
                    <span>{input.title}</span>
                    <input
                        className={input.className}
                        name={input.name}
                        id={input.id}
                        type={input.type}
                        value={input.value}
                        onChange={handleOnChange}
                        placeholder={input.placeholder}
                        required={input.required}
                        disabled={input.disabled}
                    />
                </div>
            ))}
        </div>
    );
}

export default Input;