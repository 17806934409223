const TableNoHeader = ({ data }) => {
    return (
        <table className='table-fixed text-black'>
        <tbody>
        {data.map((item, index) => (
            <tr key={index}>
            <td className='font-semibold'>{item.title}</td>
            <td>{item.value}</td>
            </tr>
        ))}
        </tbody>
        </table>
    )
}

export default TableNoHeader;