import Cookies from 'js-cookie';

const initialState = {
  isLogin: false,
  user: {},
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case "USER_SUCCESS":
    case "LOGIN_SUCCESS":
      const expirationTime = 3600; // 1 Jam 3600
      Cookies.set("id", payload.id, { expires: expirationTime });
      Cookies.set("email", payload.email, { expires: expirationTime });
      Cookies.set("username", payload.username, { expires: expirationTime });
      if (payload.accessToken) {
        Cookies.set("accessToken", payload.accessToken, { expires: expirationTime });
      }
      if (payload.refreshToken) {
        Cookies.set("refreshToken", payload.refreshToken);
      }
      Cookies.set("isLogin", true, { expires: expirationTime });

      setTimeout(() => {
        Cookies.remove("id");
        Cookies.remove("email");
        Cookies.remove("username");
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        Cookies.remove("isLogin");
      }, expirationTime * 1000);
      return {
        ...state,
        isLogin: true,
        user: payload,
      };
    case "AUTH_ERROR":
    case "LOGOUT":
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("username");
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("isLogin");
      return {
        ...state,
        isLogin: false,
        user: {},
      };

    default:
      return state;
  }
};

export default userReducer;