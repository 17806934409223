import {useNavigate, useParams, Link} from "react-router-dom"
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from "react";
import {API} from "../config/api";
import {convertToRupiah} from "../utils/rupiah";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import {setLoading} from "../store/actions/lodaing";
import Input from "../components/Input/Input";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import TableNoHeader from "../components/Table/TableNoHeader";
import ImageModal from "../components/Modal/ImageModal";
import NotFound from "../components/Loading/NotFound";
import Swal from "sweetalert2";

export default () => {
    const [pageData, setPageData] = useState({});
    const [discount, setDiscount] = useState({});
    const [couponCode, setCouponCode] = useState("");
    const [isDiskon, setIsDiskon] = useState(false);
    const [isOnSubmit, setIsOnSubmit] = useState(false);
    const [isOnSubmitOrder, setIsOnSubmitOrder] = useState(false);
    const [valQty, setValQty] = useState(1);
    const [potongan, setPotongan] = useState(null);
    const [total, setTotal] = useState(null);
    const [isError, setIsError] = useState(false);
    const [form, setForm] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
    });
    const [message, setMessage] = useState({
        status: false,
        response: "",
        desc: "",
    });

    const { isLoading } = useSelector((state) => state);
    const dispatch = useDispatch();
    const router = useNavigate();
    const { slug } = useParams();

    const getProduct = async () => {
        try {
            dispatch(setLoading(true));
            const resp = await API.get(`/product/public/${slug}`);
            setPageData(resp.data.data);
            setTotal(resp.data.data.price);
            dispatch(setLoading(false));
            } catch (err) {
            dispatch(setLoading(true));
            setIsError(true);
            dispatch(setLoading(false));
            }
    };

    useEffect(() => {
        getProduct();
    }, []);

    const calculateDiscount = () => {
        if ((pageData.price * valQty) >= discount.min_order) {
            if (discount.type == "1") {
                setPotongan(discount.discount * valQty);
                const diskont = discount.discount * valQty;
                setTotal((pageData.price * valQty) - diskont);
            } else if (discount.type == "2") {
                setPotongan(discount.discount);
                const diskont = discount.discount;
                setTotal((pageData.price * valQty) - diskont);
            }
        } else {
            removeCoupon();
        }
    }

    useEffect(() => {
        calculateDiscount();
    }, [valQty]);

    const handleOnChangeKupon = (e) => {
        setCouponCode(e.target.value);
    };

    const checkCoupon = async (e) => {
        e.preventDefault();
        console.log("KESINI KAN")
        try {
            setIsOnSubmit(true);
            const resp = await API.get(`/coupon/check?code=${couponCode}&id=${pageData.id}&subtotal=${pageData.price * valQty}`);
            const result = resp.data.data;
            setDiscount(result);
            if (result.type == "1") {
                setPotongan(result.discount * valQty);
                const diskont = result.discount * valQty;
                setTotal((pageData.price * valQty) - diskont);
            } else if (result.type == "2") {
                setPotongan(result.discount);
                const diskont = result.discount;
                setTotal((pageData.price * valQty) - diskont);
            }
            setIsDiskon(true);
            setIsOnSubmit(false);
            setMessage({
                status: true,
                response: "success",
                desc: result.title
            });
        } catch(err) {
            setIsOnSubmit(true);
            setIsDiskon(false);
            setDiscount({
                product: "",
                discount: 0,
                type: "",
                title: ""
            });
            setMessage({
                status: true,
                response: "failed",
                desc: "Kupon Tidak Ditemukan"
            });
            setPotongan(0);
            setTotal(pageData.price * valQty);
            setIsOnSubmit(false);
        }
    }

    const removeCoupon = () => {
        setCouponCode("");
        setIsDiskon(false);
        setDiscount({
            product: "",
            discount: 0,
            type: "",
            title: ""
        });
        setPotongan(0);
        setTotal(pageData.price * valQty);
        setMessage({
            status: false,
            response: "",
            desc: ""
        });
    }

    const handleOnChange = (e) => {
        setForm({
        ...form,
        [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsOnSubmitOrder(true);
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            }

            const data = {
                id_product: pageData.id,
                user: pageData.user.id,
                is_discount: isDiskon ? "1" : "0",
                id_coupon: isDiskon ? discount.id : null,
                status: "pending",
                name: form.name,
                email: form.email,
                phone: form.phone,
                address: form.address,
                discount: isDiskon ? potongan : null,
                total: total,
                qty: valQty,
            }

            const resp = await API.post("/transaction/submit", data, config);
            setIsOnSubmitOrder(false);
            Swal.fire("Good job!", "Order Success", "success");
            router(`/invoice/${resp.data.data}`);
        } catch(err) {
            setIsOnSubmitOrder(true);
            Swal.fire("Oops...", "Gagal Order", "error");
            setIsOnSubmitOrder(false);
        }
    }

    const tambahQty = (e) => {
        e.preventDefault();
        if (valQty < pageData.stocks) {
            setValQty(valQty + 1);
        }
    }

    const kurangiQty = (e) => {
        e.preventDefault();
        if (valQty > 1) {
            setValQty(valQty - 1);
        }
    }

    const dataInput = [
        {
            className: "mainInput",
            name: "name",
            id: "name",
            type: "text",
            placeholder: "Nama Anda",
            required: true,
            disabled: pageData.stocks <= 0
        },
        {
            className: "mainInput",
            name: "email",
            id: "email",
            type: "email",
            placeholder: "Email Anda",
            required: true,
            disabled: pageData.stocks <= 0
        },
        {
            className: "mainInput",
            name: "phone",
            id: "phone",
            type: "number",
            placeholder: "Nomor HP Anda",
            required: true,
            disabled: pageData.stocks <= 0
        },
    ];

    const rincian = [
        {
            title: "Harga",
            value: convertToRupiah(pageData.price)
        },
        {
            title: "Jumlah",
            value: valQty
        },
        {
            title: "Subtotal",
            value: <span className={`${isDiskon && "line-through"}`}>{convertToRupiah(pageData.price * valQty)}</span>
        },
        {
            title: "Diskon",
            value: <span className="text-red-600">{isDiskon ? convertToRupiah(potongan) : "-"}</span>
        },
        {
            title: "Total",
            value: isDiskon ? convertToRupiah(total) : convertToRupiah(pageData.price * valQty)
        },
    ];

    if (isError) {
        return <NotFound />;
    }
    
    return (
        <div className="mainContainer mt-10">
            <div>
            {pageData.stocks <= 0 && (
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-lg shadow-lg">
                    <span className="text-red-600 text-center text-lg font-bold">Produk Telah Habis</span>
                </div>
            )}
            <ImageModal src={pageData.product_image} alt={pageData.title} />
                <h1>{pageData.title}</h1>
                <p className="text-sm italic">by <Link to={`/${pageData.user?.username}`}>{pageData.user?.username}</Link></p>
                <p className="mt-5">{pageData.description}</p>
                <p className="text-[#0077b6]">{convertToRupiah(pageData.price)}</p>
            </div>
            <div>
                <form onSubmit={handleSubmit}>
                    <Input
                        dataInput={dataInput} 
                        handleOnChange={handleOnChange} 
                    />
                    <textarea
                        name="address"
                        id="address"
                        className="mainInput resize-none h-28"
                        onChange={handleOnChange}
                        placeholder="Alamat Anda"
                        disabled={pageData.stocks <= 0}
                        required
                    ></textarea>
                    <div>
                        <button onClick={kurangiQty} className="bg-slate-300 hover:bg-slate-400 px-3 py-1 rounded">-</button>
                        <input 
                        className="p-1 m-1 bg-slate-50 w-[50px] rounded my-2 border border-slate-400 text-center"
                        value={valQty}
                        name="qty"
                        type="number"
                        disabled
                        />
                        <button onClick={tambahQty} className="bg-slate-300 hover:bg-slate-400 px-3 py-1 rounded">+</button>
                        <p className="text-sm italic text-slate-700">Sisa Stok: {pageData.stocks}</p>
                    </div>
                    {pageData.stocks > 0 && (
                    <div>
                    <div className="bg-sky-100 border border-sky-600 p-3 my-3 rounded">
                        <p>Kamu punya kupon diskon?</p>
                        <div className="flex flex-col md:flex-row flex-wrap justify-between">
                            <div className="flex-[60%] mr-1 relative">
                                <input 
                                    type="text"
                                    placeholder="Masukkan Kode Kupon"
                                    className="p-2 bg-slate-50 w-full rounded my-2 border border-slate-400"
                                    onChange={handleOnChangeKupon}
                                    value={couponCode}
                                    name="coupon-code"
                                    id="coupon-code"
                                />
                                {isDiskon && 
                                <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    className="cursor-pointer mt-5 text-red-700 absolute right-4 focus:outline-none"
                                    onClick={removeCoupon}
                                />}
                            </div>
                            <div className="flex-[30%] ml-1">
                                <button className={isOnSubmit ? "loadingButton" : "mainButton"} disabled={isOnSubmit} onClick={checkCoupon}>{isOnSubmit ? "Loading..." : "Cek Kupon"}</button>
                            </div>
                        </div>
                        {(message.status == true && message.response == "success") ? (
                            <div className="bg-green-100 p-1 mt-2 rounded border border-green-500">
                            <p className="text-green-500 text-center mb-0">Kupon berhasil ditambahakan</p>
                            <p className="text-green-500 text-center mb-0 font-bold">{message.desc}</p>
                            </div>
                        ) : (message.status == true && message.response == "failed") ? (
                            <div className="bg-red-100 p-1 mt-2 rounded border border-red-500">
                            <p className="text-red-500 text-center mb-0">{message.desc}</p>
                            </div>
                        ) : (<div></div>)}
                    </div>
                    <TableNoHeader data={rincian} />
                    <button className={isOnSubmitOrder ? "loadingButton" : "mainButton"} disabled={isOnSubmit} type="submit">
                        {isOnSubmitOrder ? "Tunggu Sebentar..." : "Pesan Sekarang"}
                    </button>
                    </div>
                    )}
                </form>
            </div>
            {isLoading && <LoadingOverlay />}
        </div>
    )
}