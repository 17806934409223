import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
    faTiktok
    } from "@fortawesome/free-brands-svg-icons";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons"

const ProfileData = ({profile, openModal}) => {
    return (
        <div className="flex flex-col md:flex-row justify-center items-start mx-3">
            <div className="flex-1 w-full">
                <h3 className="underline decoration-[#0077b6] decoration-solid decoration-2 underline-offset-8 mt-5 mb-8">General Information</h3>
                    <div className="flex flex-col md:flex-row">
                        <div className="flex-1 flex flex-col mr-2">
                            <img className="w-[170px] h-[170px] rounded mb-3 mx-auto" src={profile.image} alt={profile.full_name} />
                            <button className="w-[170px] mx-auto p-1 bg-[#0096c7] rounded my-1 text-sm font-semibold text-white hover:bg-[#0077b6]"><FontAwesomeIcon icon={faPenToSquare} /> Profile Picture</button>
                            <button onClick={() => openModal('editProfile')} className="w-[170px] mx-auto p-1 bg-[#0096c7] rounded my-1 text-sm font-semibold text-white hover:bg-[#0077b6]"><FontAwesomeIcon icon={faPenToSquare} /> Profile</button>
                            <button onClick={() => openModal('changePassword')} className="w-[170px] mx-auto p-1 bg-[#0096c7] rounded my-1 text-sm font-semibold text-white hover:bg-[#0077b6]"><FontAwesomeIcon icon={faPenToSquare} /> Password</button>
                    </div>
                    <div className="flex-[1.5] flex flex-col ml-2 mt-3 md:mt-0">
                        <div className="mb-2">
                            <span className="text-xl font-bold text-[#0077b6]">Nama Lengkap</span>
                            <br/>
                            <span className="text-sm">{profile.full_name}</span>
                        </div>
                        <div className="mb-2">
                            <span className="text-xl font-bold text-[#0077b6]">Username</span>
                            <br/>
                            <span className="text-sm">{profile.username}</span>
                        </div>
                        <div className="mb-2">
                            <span className="text-xl font-bold text-[#0077b6]">Email</span>
                            <br/>
                            <span className="text-sm">{profile.email}</span>
                        </div>
                        <div className="mb-2">
                            <span className="text-xl font-bold text-[#0077b6]">Nomor HP</span>
                            <br/>
                            <span className="text-sm">{profile.phone}</span>
                        </div>
                        <div className="mb-2">
                            <span className="text-xl font-bold text-[#0077b6]">Bio Creator</span>
                            <br/>
                            <span className="text-sm">{profile.bio_creator}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-1 w-full">
                <h3 className="underline decoration-[#0077b6] decoration-solid decoration-2 underline-offset-8 mt-5 mb-8">Social Media</h3>
                <div>
                    {profile.facebook && 
                        <div className="flex flex-row items-center my-1">
                            <FontAwesomeIcon icon={faFacebook} className="text-3xl mr-2" />
                            <a href={`https://instagram.com/${profile.facebook}`} target="_blank" rel="noopener noreferrer">
                                <span>{profile.facebook}</span>
                            </a>
                        </div>
                    }
                    {profile.instagram && 
                        <div className="flex flex-row items-center my-1">
                            <FontAwesomeIcon icon={faInstagram} className="text-3xl mr-2" />
                            <a href={`https://instagram.com/${profile.instagram}`} target="_blank" rel="noopener noreferrer">
                                <span> @{profile.instagram}</span>
                            </a>
                        </div>
                    }
                    {profile.twitter && 
                        <div className="flex flex-row items-center my-1">
                            <FontAwesomeIcon icon={faTwitter} className="text-3xl mr-2" />
                            <a href={`https://twitter.com/${profile.twitter}`} target="_blank" rel="noopener noreferrer">
                                <span> @{profile.twitter}</span>
                            </a>
                        </div>
                    }
                    {profile.tiktok && 
                        <div className="flex flex-row items-center my-1">
                            <FontAwesomeIcon icon={faTiktok} className="text-3xl mr-2" />
                            <a href={`https://tiktok.com/@${profile.tiktok}`} target="_blank" rel="noopener noreferrer">
                                <span> @{profile.tiktok}</span>
                            </a>
                        </div>
                    }
                    {profile.youtube && 
                        <div className="flex flex-row items-center my-1">
                            <FontAwesomeIcon icon={faYoutube} className="text-3xl mr-2" />
                            <a href={`https://youtube.com/${profile.youtube}`} target="_blank" rel="noopener noreferrer">
                                <span> {profile.youtube}</span>
                            </a>
                        </div>
                    }
                    <h3 className="underline decoration-[#0077b6] decoration-solid decoration-2 underline-offset-8 mt-5 mb-8">Donation</h3>
                    {profile.saweria && 
                        <div className="flex flex-row items-center my-1">
                            <span className="mr-2">Saweria: </span>
                            <a href={`https://saweria.co/${profile.saweria}`} target="_blank" rel="noopener noreferrer">
                                <span> {profile.saweria}</span>
                            </a>
                        </div>
                    }
                    {profile.trakteer && 
                        <div className="flex flex-row items-center my-1">
                            <span className="mr-2">Trakteer: </span>
                            <a href={`https://trakteer.id/${profile.trakteer}/tip`} target="_blank" rel="noopener noreferrer">
                                <span> {profile.trakteer}</span>
                            </a>
                        </div>
                    }
                    <button onClick={() => openModal('editSocmed')} className="mx-auto mt-5 p-1 bg-[#0096c7] rounded my-1 text-sm font-semibold text-white hover:bg-[#0077b6]"><FontAwesomeIcon icon={faPenToSquare} /> Social Media & Donation</button>
                </div>
            </div>
        </div>
    )
}

export default ProfileData;