import {useState} from 'react';
import AuthModal from '../Modal/AuthModal';

const AuthMenu = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button className="p-2 bg-[#0096c7] w-full rounded text-sm md:text-base font-bold text-white hover:bg-[#0077b6]" onClick={openModal}>Creator Login</button>
      <AuthModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default AuthMenu;