import {convertToRupiah} from "../../utils/rupiah";
import {useNavigate} from "react-router-dom";

const ProductCard = ({products}) => {
    const router = useNavigate();
    return (
        <div>
            {products.map((product) => (
            <div key={product.id} 
                className="w-full border-2 border-black p-2 rounded-lg flex justify-start items-center flex-row bg-slate-100 my-2 cursor-pointer hover:bg-slate-200 hover:shadow-md"
                onClick={() => router(`/page/${product.slug}`)}
            >
                <div className="w-[60px] h-[60px] flex justify-center items-center overflow-hidden mr-4 rounded-md">
                    <img src={product.product_image} alt={product.title} className="w-full h-auto"/>
                </div>
                <div className="flex-[60%] mr-3">
                    <p className="text-base font-semibold">{product.title}</p>
                </div>
                <div className="flex-[15%] bg-yellow-400 py-2 px-4 rounded-xl text-center">
                    <span className="text-xs font-semibold">{convertToRupiah(product.price)}</span>
                </div>
            </div>
            ))}
        </div>
    )
}

export default ProductCard;