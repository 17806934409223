import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../store/actions/lodaing"
import {API} from "../../../config/api";
import {useNavigate} from "react-router-dom";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";
import Pagination from "../../../components/Pagination/Pagination";
import TableGeneral from "../../../components/Table/TableGeneral";
import ModalConfirm from "../../../components/Modal/ModalConfirm";
import Search from "../../../components/Search/Search";
import {convertToRupiah} from "../../../utils/rupiah";
import {getDate} from "../../../utils/date"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSquarePlus, faSquarePen, faTrashCan} from '@fortawesome/free-solid-svg-icons';

export default () => {
    const [coupons, setCoupons] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [idDelete, setIdDelete] = useState(null);
    const [msg, setMsg] = useState({
        error: false,
        text: ""
    });
    const [search, setSearch] = useState("");
    const router = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state);

    const headers = ["nomor_kupon", "title", "coupon_code", "nama_produk", "discount_type", "min_order", "value", "masa_berlaku", "action"];
    const headerLabels = ["No", "Nama Kupon", "Kode Kupon", "Nama Produk", "Tipe Kupon", "Minimal Order", "Value", "Masa Berlaku", "Action"];
    const headerStyles = ["w-[50px]", "w-[150px]", "w-[100px]", "w-[150px]", "w-[100px]", "w-[100px]", "w-[100px]", "w-[150px]", "w-[150px]"];

    const getCoupons = async () => {
        try {
            dispatch(setLoading(true));
            const resp = await API.get(`/coupon/all?page=${currentPage}&limit=7&search=${search}`);
            const value = resp.data.data;
            let hasil = [];
            for (let i = 0; i < value.length; i++) {
                hasil.push({
                    id: value[i].id,
                    title: value[i].title,
                    coupon_code: <strong>{value[i].coupon_code}</strong>,
                    nama_produk: value[i].nama_produk,
                    discount_type: value[i].discount_type == "1" ? "Potongan Persen" : "Potongan Harga",
                    min_order: value[i].min_order ? convertToRupiah(value[i].min_order) : "-",
                    value: value[i].discount_type == "1" ? value[i].value + "%" : convertToRupiah(value[i].value),
                    masa_berlaku: value[i].exp_coupon ? getDate(value[i].start_coupon) + " -> " + getDate(value[i].exp_coupon) : "Berlaku selamanya",
                    action:
                    [
                        <FontAwesomeIcon
                            key={value[i].id+"edit"}
                            icon={faSquarePen}
                            className="cursor-pointer text-2xl m-1 text-sky-600 hover:text-sky-700"
                            title="Edit Product"
                            // onClick={() => handleUpdate(value[i].id, "approved")}
                        />,
                        <FontAwesomeIcon
                            key={value[i].id+"delete"}
                            icon={faTrashCan}
                            className="cursor-pointer text-2xl m-1 text-red-600 hover:text-red-700"
                            title="Delete product"
                            onClick={() => handleDelete(value[i].id)}
                        />
                    ]
                })
            }
            hasil.forEach((objek, index) => {
                objek.nomor_kupon = (index + 1).toString();
            });
            setCoupons(hasil);
            setTotalPage(resp.data.totalPages);
            dispatch(setLoading(false));
        } catch(err) {
            dispatch(setLoading(true));
            console.log("Gagal fetch data");
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        getCoupons()
    }, [currentPage])

    const openModal = () => {
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleDelete = (id) => {
        setIdDelete(id);
        openModal();
    };

    const deleteStatus = async (id) => {
        try {
            await API.delete(`/coupon/delete/${id}`);
            setMsg({ error: false, text: "Sukses hapus kupon" });
            getCoupons();
        } catch (err) {
            setMsg({
                error: true,
                text: "Ada kesalahan server, coba lagi nanti",
            });
        }
        setTimeout(() => {
            setMsg({ error: false, text: "" });
        }, 2000);
    };

    useEffect(() => {
        if (confirmDelete) {
            closeModal();
            deleteStatus(idDelete);
            setConfirmDelete(null);
        }
    }, [confirmDelete]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const nextPage = () => {
        if (currentPage < totalPage) {
            setCurrentPage(currentPage + 1);
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const handleSearch = () => {
        setCurrentPage(1);
        getCoupons();
    }

    return (
        <div className="creatorContainer">
            <h1>Daftar Kupon Kamu</h1>
            <hr className="my-3"/>
            <Search 
                handleSearchChange={handleSearchChange} 
                handleSearch={handleSearch}
                placeholder="Cari Kupon"
                note="Cari berdasarkan nama produk atau kode kupon"
            />
            <hr className="my-3"/>
            <div 
                className="bg-green-600 w-48 p-1 rounded flex flex-row items-center hover:bg-green-700 cursor-pointer"
                onClick={() => router("/add-coupon")}
            >
                <FontAwesomeIcon
                    icon={faSquarePlus}
                    className="text-white text-2xl m-1 ml-2"
                />
                <span className="text-white text-base font-semibold ml-2">Tambah Kupon</span>
            </div>

            {msg.text && (
                <div className={`p-1 my-3 rounded border ${msg.error ? "border-red-500 bg-red-100" : "border-green-500 bg-green-100"}`}>
                    <p className={`text-center mb-0 font-bold ${msg.error ? "text-red-500" : "text-green-500"}`}>{msg.text}</p>
                </div>
            )}

            {coupons.length == 0 ? (
                <p className="text-gray-500 mt-7 ml-1">Belum ada kupon.</p>
                ) : (
                    
                    <TableGeneral
                        data={coupons}
                        headers={headers}
                        headerLabels={headerLabels}
                        headerStyles={headerStyles}
                    />
            )}

            <Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                handlePageChange={handlePageChange}
                prevPage={prevPage}
                nextPage={nextPage}
            />

            <ModalConfirm
                setConfirm={setConfirmDelete}
                isOpen={isModalOpen}
                onClose={closeModal}
                action={`Apakah kamu yakin mau menghapus kupon ini?`}
            />

        {isLoading && <LoadingOverlay/>}
        </div>
    )
}