import {Link} from "react-router-dom"
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from "react";
import {API} from "../config/api";
import {setLoading} from "../store/actions/lodaing"
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import NotFound from "../components/Loading/NotFound"

export default () => {
    const [topUsers, setTopUsers] = useState([]);
    const [isError, setIsError] = useState(false);

    const { isLoading } = useSelector((state) => state);
    const dispatch = useDispatch();

    const getTopUser = async() => {
        try {
            dispatch(setLoading(true));
            const resp = await API.get(`/transaction/rank`);
            setTopUsers(resp.data.data);
            dispatch(setLoading(false));
        } catch(err) {
            dispatch(setLoading(true));
            setIsError(true);
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        getTopUser();
    }, []);

    if (isError) {
        return <NotFound />;
    }

    return (
        <div className="mainContainer">
            <h1 className="text-center my-5">Shouhin Shoukai 商品紹介</h1>
            <p>ShoSho PRO adalah sebuah platform yang inovatif dan sangat berguna bagi para kreator yang ingin menjual dan mengelola produk mereka secara efisien. Dengan ShoSho PRO, kreator dapat dengan mudah memasarkan dan menjual karya mereka kepada audiens yang lebih luas. Platform ini menawarkan beragam fitur yang memudahkan pengelolaan produk, mulai dari mengunggah foto, menambahkan deskripsi, hingga mengatur harga dan inventaris.</p>
            <p>Salah satu keunggulan ShoSho PRO adalah kemampuannya dalam memantau penjualan dan performa produk secara real-time. Dengan fitur analitik yang canggih, kreator dapat melihat data penjualan, melacak popularitas produk, dan mendapatkan wawasan yang berharga untuk meningkatkan strategi pemasaran. ShoSho PRO juga menyediakan alat untuk mengelola inventaris dengan mudah, memberikan notifikasi saat stok produk mulai menipis atau habis.</p>
            <p>Selain itu, ShoSho PRO juga menyediakan berbagai opsi pembayaran yang aman dan fleksibel. Para pelanggan dapat membeli produk dengan mudah melalui berbagai metode pembayaran yang disediakan, termasuk kartu kredit, transfer bank, atau e-wallet. Dengan integrasi yang lancar dengan berbagai sistem pembayaran.</p>
            <p>ShoSho PRO memastikan pengalaman berbelanja yang lancar dan menyenangkan bagi pelanggan, sehingga meningkatkan peluang penjualan bagi para kreator. Dengan ShoSho PRO, menjual dan mengelola produk menjadi lebih efisien dan mudah, membantu kreator untuk mencapai kesuksesan dalam bisnis online mereka.</p>
            <h1>Top Creator Bulan Ini</h1>
            <p className="text-sm ml-1">Data rank berdasarkan jumlah transaksi tiap bulan</p>
            <div className="flex flex-row">
            {topUsers.map((user) => (
                <div key={user.id}>
                    <Link to={`/${user.username}`}>
                        <button className="my-3 mx-1 rounded py-2 px-3 bg-[#0096c7] hover:bg-[#0077b6] text-white font-semibold">{user.username} #{user.transactionCount}</button>
                    </Link>
                </div>
            ))}
            </div>
            {isLoading && <LoadingOverlay />}
        </div>
    )
}