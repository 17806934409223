import {useState} from "react"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons';

const SuggestionBox = ({ suggestions, handleSelect, placeHolder, label, selected }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        const filtered = suggestions.filter((item) =>
        item.title.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredSuggestions(filtered);
    };

    const handleSelectItem = (itemId, val) => {
        const selectedItem = suggestions.find((item) => item.id === itemId);
        if (selectedItem) {
        handleSelect(itemId, val);
        setInputValue(selectedItem.title);
        setFilteredSuggestions([]);
        }
    };

    const handleClearProduct = () => {
        handleSelect(null, false);
        setInputValue("");
    };

    return (
        <div className="relative my-3">
        <label htmlFor="suggestion-box">{label}</label>
        <input
            type="text"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
            id="suggestion-box"
            placeholder={placeHolder}
            value={inputValue}
            onChange={handleInputChange}
            required
        />
        {selected && (
            <button
            className="absolute right-4 text-gray-500 focus:outline-none"
            onClick={handleClearProduct}
            >
            <FontAwesomeIcon className="text-red-700 mt-3 text-lg" icon={faCircleXmark} />
            </button>
        )}
        {(filteredSuggestions.length > 0 && inputValue !== "") && (
            <ul className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md">
            {filteredSuggestions.slice(0, 5).map((item) => (
                <li
                key={item.id}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleSelectItem(item.id, true)}
                >
                {item.title}
                </li>
            ))}
            </ul>
        )}
        </div>
    );
};

export default SuggestionBox;
