import Input from "../Input/Input";

const ModalProfile = ({ isOpen, onClose, data, handleOnChange, handleOnSubmit, header }) => {

const closeModal = () => {
    onClose();
};

return (
    <div className={`fixed z-50 inset-0 overflow-y-auto transition-opacity ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <div className="flex items-start justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={closeModal}></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:max-w-lg">
            <div className="bg-slate-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h4>{header}</h4>
                <form onSubmit={handleOnSubmit}>
                    <Input
                        dataInput={data}
                        handleOnChange={handleOnChange}
                    />
                    <button type="submit" className="mainButton">{header}</button>
                </form>
            </div>
        </div>
    </div>
    </div>
);
};

export default ModalProfile;