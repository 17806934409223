const Search = ({handleSearchChange, handleSearch, placeholder, note}) => {
    return (
        <div>
            <div className="flex flex-row">
                <div className="relative">
                    <input 
                        type="text" 
                        id="inputSearch" 
                        onChange={handleSearchChange}
                        className="block rounded px-2.5 pb-2 pt-5 w-full text-sm text-gray-900 bg-white border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#0077b6] peer"
                        placeholder=" " 
                    />
                    <label 
                        htmlFor="inputSearch" 
                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-7 origin-[0] left-2.5 peer-focus:text-[#0077b6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                        {placeholder}
                    </label>
                </div>
                <button onClick={handleSearch} className="menuButton ml-2">
                    Search
                </button>
                </div>
            {note ? <p className="text-xs my-2 italic text-[#0077b6]">{note}</p> : ""}
        </div>
    )
}

export default Search;