import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {convertToRupiah} from "../utils/rupiah";
import {API} from "../config/api";
import {setLoading} from "../store/actions/lodaing"
import LoadingOverlay from '../components/Loading/LoadingOverlay';
import TableNoHeader from '../components/Table/TableNoHeader';
import NotFound from '../components/Loading/NotFound';

export default () => {
    const [pesanan, setPesanan] = useState([]);
    const [pemesan, setPemesan] = useState([]);
    const [isError,setIsError] = useState(false);
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state);
    const { invoice } = useParams();

    const getInvoice = async () => {
        try {
            dispatch(setLoading(true));
            const resp = await API.get(`/transaction/invoice/${invoice}`);
            const value = resp.data.data;
            const pemesan = [
                {
                    title: "Nama",
                    value: value.name
                },
                {
                    title: "Email",
                    value: value.email
                },
                {
                    title: "No HP",
                    value: value.phone
                },
                {
                    title: "Alamat",
                    value: value.address
                },
            ];
            setPemesan(pemesan);
            const pesanan = [
                {
                    title: "Produk",
                    value: value.product.title
                },
                // {
                //     title: "Deskripsi",
                //     value: value.product.description
                // },
                {
                    title: "Harga",
                    value: convertToRupiah(value.product.price)
                },
                {
                    title: "Jumlah Pesanan",
                    value: value.qty
                },
                {
                    title: "Subtotal",
                    value: convertToRupiah(value.product.price * value.qty)
                },
                {
                    title: "Kode Kupon",
                    value: <span className='font-semibold'>{value.coupon ? value.coupon.coupon_code : "-"}</span>
                },
                {
                    title: "Diskon",
                    value: <span className='text-red-600'>{value.discount ? convertToRupiah(value.discount) : "-"}</span>
                },
                {
                    title: "Total",
                    value: convertToRupiah(value.total)
                },
                {
                    title: "Status",
                    value: <span className={`${(value.status == "approved") ? "text-green-600" : (value.status == "rejected") ? "text-red-600" : "text-orange-600"}`}>{(value.status == "approved") ? "Sudah dibayar" : (value.status == "pending") ? "Belum dibayar" : "Pesanan dibatalkan"}</span>
                },
            ];
            setPesanan(pesanan);
            dispatch(setLoading(false));
        } catch(err) {
            dispatch(setLoading(true));
            setIsError(true);
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        getInvoice()
    },[])

    if (isError) {
        return <NotFound />;
    }

    return (
        <div className="mainContainer">
            <div>
                <h1>Invoice Detail</h1>
                <p>{invoice}</p>
                <hr className='my-3'/>
                <TableNoHeader
                    data={pesanan}
                />
                <hr className='my-3'/>
                <p>Pesanan akan dikirim ke:</p>
                <TableNoHeader
                    data={pemesan}
                />
                <p>Admin kami akan segera menghubungi anda dalam waktu maksimal 3x24jam.</p>
            </div>
        {isLoading && <LoadingOverlay />}
        </div>
    )
}